import React, { useEffect, useRef, useState } from "react"
import Flickity from "react-flickity-component"
import "flickity/dist/flickity.min.css"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import MarkDown from "react-markdown"

//component
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LiveChat from "../../components/Home/livechat";
import TestimonialsText from "../../components/Home/testimonials-text"

// img
import souleBgimg from "../../../images/uploads/souleway/soule-bg-img.png"
import souleLogo from "../../../images/uploads/souleway/soule-logo.png"
import souleAboutimg1 from "../../../images/uploads/souleway/soule-about-img-1.png"
import souleAboutimg2 from "../../../images/uploads/souleway/soule-about-img-2.png"
import soulewayMobilebanner from "../../../images/uploads/souleway/souleway-mobile-banner.png"
import souleChallengeBg from "../../../images/uploads/souleway/soule-challenge-bg.png"
import Soule1 from "../../../images/uploads/souleway/decodeup-soulway-slider-ss-1.png"
import Soule2 from "../../../images/uploads/souleway/decodeup-soulway-slider-ss-2.png"
import Soule3 from "../../../images/uploads/souleway/decodeup-soulway-slider-ss-3.png"
import Soule4 from "../../../images/uploads/souleway/decodeup-soulway-slider-ss-4.png"
import Soule5 from "../../../images/uploads/souleway/decodeup-soulway-slider-ss-5.png"
import Soule6 from "../../../images/uploads/souleway/decodeup-soulway-slider-ss-6.png"
import Soule7 from "../../../images/uploads/souleway/decodeup-soulway-slider-ss-7.png"
import Soule8 from "../../../images/uploads/souleway/decodeup-soulway-slider-ss-8.png"

// case Stydy
import addcs1 from "../../../images/uploads/decodeup-contemporary-additional-case-study.png"
import addcs2 from "../../../images/uploads/decodeup-cwatch-additional-case-study.png"

const SouleWay = props => {
  const SEOdata = props.data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.pageData.edges[0].node.frontmatter?.Hreflang
  const [margin, setMargin] = useState()
  const flRef = useRef()
  const flesol = useRef()

  useEffect(() => {
    let box = document.querySelector(".container")
    let width = box.offsetWidth
    let innerw = window.innerWidth
    let paddingApplied = innerw - width
    setMargin(paddingApplied)
  })

  const options = {
    lazyLoad: true,
    prevNextButtons: true,
    cellAlign: "left",
    contain: true,
    freeScroll: true,
    pageDots: false,
    percentPosition: true,
    adaptiveHeight: true,
  }

  const options2 = {
    lazyLoad: true,
    prevNextButtons: false,
    cellAlign: "left",
    contain: true,
    freeScroll: true,
    pageDots: false,
    percentPosition: true,
    adaptiveHeight: false,
  }

  const onMouseEnter = () => {
    flRef.current.flkty.player.play()
  }
  const onMouseLeave = () => {
    flRef.current.flkty.player.stop()
  }
  const onMouseEnter1 = () => {
    flesol.current.flkty.player.play()
  }
  const onMouseLeave1 = () => {
    flesol.current.flkty.player.stop()
  }
  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "souleway"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />

        {/* banner  section */}
        <section className="h-full  xl:h-screen case-draft-section">
          <div className="h-full container mx-auto  ">
            <div className="pb-20 pt-1-63 md:py-2-00 lg:pt-64 h-full flex flex-col justify-center">
              <div className="mb-6-6 lg:mb-50 w-full  case-study-project-heading ">
                <h4 className="pr-5 lg:pl-0 mb-1-2 lg:mb-3-2 font-poppings-normal text-p3 lg:text-p2 text-term-primary">
                  Redesign Website & Improvement UI/UX
                </h4>
                <h1 className="mb-3 lg:mb-5 font-poppings-bold text-h2 lg:text-h0 xl:text-h1 text-term-primary">
                  eCommerce of Minimalist Products
                </h1>
                <p className="w-full lg:w-11/20  font-worksans-normal text-p4  md:text-h5 lg:text-p2 text-term-primary">
                  Sometimes you need a makeover and no, we’re not talking about
                  your closet. Old websites can turn customers away, and
                  complicated processes must be expressed in simple language to
                  reach a wide audience. Here are some highlights of how we
                  created a gorgeous web presence for Brains & Beauty.
                </p>
              </div>
              <div className="project-details-draft">
                <div className="flex flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      CLIENT
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Jonahthan
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      COUNTRY
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Germany
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      SERVICES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      UI/UX Design, Web Design, Development
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      TECHNOLOGIES USED
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Wordpress, WooCommerce
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      INDUSTRIES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Luxury & E-Commerce
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Banner img section */}
        <section>
          <img
            className="mx-auto"
            src={souleBgimg}
            alt="Souleway Experts Decodeup Technologies"
          />
        </section>

        {/* Souleway Animation Sections */}

        <section className="mt-8 mb-16 lg:mt-0 lg:mb-24 soule-anim-text relative">
          <div className="container mx-auto">
            <div className="px-3 py-2 lg:py-9 lg:px-6 border-t-1 border-b-1 border-tone-borderDark relative flex items-start overflow-hidden">
              <div className="soule-list flex items-start overflow-hidden">
                <div className="text-p2 lg:text-p1 flex items-start text-term-primary font-worksans font-worksans-normal">
                  <span className="item-txt">E-COMMERCE UI/UX DESIGN </span>
                  <span className="item-txt">-- WEBSITE DESIGN </span>
                  <span className="item-txt">-- DEVELOPMENT </span>
                  <span className="item-txt">
                    -- HANDMADE DESIGNER MANUFACTUROR IN GERMANY{" "}
                  </span>
                  <span className="item-txt">-- WORDPRESS </span>
                  <span className="item-txt">-- E-COMMERCE </span>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <img
              className="soule-logo"
              src={souleLogo}
              alt="DecodeUp Souleway DecodeUp Technologies"
            />
          </div>
        </section>

        {/* Introduction section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="flex justify-start gap-14">
              <div className="w-1/5 hidden lg:flex flex-col justify-between">
                <div className="w-full ">
                  <img
                    src={souleAboutimg1}
                    alt="Souleway DecodeUp Technologies"
                  />
                </div>
                <div></div>
              </div>
              <div className="w-full lg:w-3/5 grow">
                <div className="mb-6 lg:mb-12">
                  <h3 class="mb-2 lg:mb-4 xl:mb-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                    Brains & Beauty - Tech That Looks Amazing and Delivers More
                  </h3>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    Our client was in need of a new website design, one that was
                    configured for mobile devices and tables with a product
                    detail page that drew customers to purchase their products.
                    The e-commerce side needed an easier variations
                    configuration to shopping would be simple. In addition, they
                    were hoping for a faster online experience and a sleek,
                    minimal style.
                  </p>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    Their work as a business is all about simplifying complex
                    processes and that's exactly what they wanted their new site
                    to reflect. Our task was to merge.
                  </p>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    Brains & Beauty to achieve a gorgeous design that would
                    perform at the highest level with modern engineering in the
                    backend.
                  </p>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    Souleway chose DecodeUp for our track record of making
                    simple sites that can complete complicated tasks.
                  </p>
                </div>
                <div className="lg:mb-12">
                  <h3 class="mb-2 lg:mb-4 xl:mb-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                    Making customers feel special
                  </h3>

                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    The client needed lots of variations for their site, options
                    such as color, stripe color, zip colour that needed to be
                    presented in a simple way on the front end so users could
                    make selections with a simple click or tap. We designed a
                    seamless product view that made it simple for customers to
                    design their items based on individual preferences.
                  </p>
                </div>
              </div>
              <div className="w-1/5 hidden lg:flex flex-col justify-between">
                <div></div>
                <div className="w-full ">
                  <img
                    src={souleAboutimg2}
                    alt="Souleway DecodeUp Technologies"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* intro bg */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <img
            className="mx-auto"
            src={soulewayMobilebanner}
            alt="Souleway Mobile Decodeup Technologies"
          />
        </section>

        {/* Challanges section */}
        <section className="mb-60 md:mb-20">
          <div className="container mx-auto">
            <div className="flex flex-wrap sm:flex-nowrap gap-3 md:gap-12 lg:gap-24">
              <div className="w-full sm:w-2/29 lg:max-w-3-68">
                <h2 className="mb-4 lg:mb-16 pr-10 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  The Challenges
                </h2>
              </div>
              <div className="w-full sm:w-7/10 flex flex-wrap">
                <h3 class="mb-2 lg:mb-4 xl:mb-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Innovative Solutions to Common Struggles
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The basic need was a new website, but this wasn't a
                  drag-and-drop situation. Souleway was looking for something
                  minimal, something attractive and something easy to use.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Balancing a visually appealing site that's intuitive and can
                  accurately manage large amounts of data requires personalized
                  attention and skillful coding. We crafted the site with these
                  needs in mind and the ability to communicate in multiple
                  languages to users around the world.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  In this case, the client knew their strategy and their needs.
                  In looking for a partner that could produce a high quality
                  user experience while also learning their culture and story
                  they found DecodeUp.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Challenge bg */}
        <section>
          <img
            className="mx-auto"
            src={souleChallengeBg}
            alt="Souleway Experts Decodeup Technologies"
          />
        </section>

        {/* solution */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="w-full mb-8 lg:mb-16">
              <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-center">
                The Solutions
              </h2>
            </div>

            <div
              id="myid"
              onMouseEnter={onMouseEnter1}
              onMouseLeave={onMouseLeave1}
            >
              <Flickity
                className={"case-studies-carousel cstm-du-casestudy-slider"}
                elementType={"div"}
                options={options2}
                ref={flesol}
              >
                <div className="mr-6 p-8 w-full rounded-lg max-w-5-54 soule-sol">
                  <h3 class="mb-2 lg:mb-4 xl:mb-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-dark-blue ">
                    First Things First...
                  </h3>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-dark-blue  font-worksans-normal">
                    We began by presenting a series of questions, which help us
                    to develop the the skeleton for the project so we can
                    customize a plan that fits this specific client:
                  </p>
                  <ul className="flex flex-col gap-3 lg:gap-5 list-none soule-solution-ul text-dark-blue  font-worksans-normal text-p4 lg:text-p3 xl:text-p2">
                    <li className="ml-6 xl:ml-10 relative">
                      What are your main objectives?
                    </li>
                    <li className="ml-6 xl:ml-10 relative">
                      Who is your audience or avatar?
                    </li>
                    <li className="ml-6 xl:ml-10 relative">
                      Are there any priorities for the project?
                    </li>
                  </ul>
                </div>
                <div className="mr-6 p-8 w-full rounded-lg max-w-5-54 soule-sol">
                  <h3 class="mb-2 lg:mb-4 xl:mb-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-dark-blue ">
                    Create your best shopping environment
                  </h3>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-dark-blue  font-worksans-normal">
                    For this web redesign, the biggest priority was the product
                    details page. The client emphasized the desire for a user
                    experience that clearly presented options and allowed users
                    to choose and see what they were buying.
                  </p>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-dark-blue  font-worksans-normal">
                    That left us with the question: what tools were necessary to
                    make this personalized experience possible?
                  </p>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-dark-blue  font-worksans-normal">
                    We worked as a team to create a clear, effective product
                    page with modules that delivered exactly the options
                    Souleway needed.
                  </p>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-dark-blue  font-worksans-normal">
                    Last but not least, we gave the entire site the upgrade it
                    needed to deliver a flawless presentation that's easy on the
                    eyes.
                  </p>
                </div>
                <div className="mr-6 p-8 w-full rounded-lg max-w-5-54 soule-sol">
                  <h3 class="mb-2 lg:mb-4 xl:mb-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-dark-blue ">
                    Looking Ahead
                  </h3>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-dark-blue  font-worksans-normal">
                    Are you unsatisfied with your current online presence? What
                    would you like today that your current technology is holding
                    you back from? Reach out to DecodeUp to start your
                    transformation.
                  </p>
                </div>
              </Flickity>
            </div>
          </div>
        </section>

        {/* Typographics */}

        <section className="mb-60 md:mb-20 xl:mb-1-40 ">
          <div className="container mx-auto">
            <div className="mb-6 md:mb-10 soule-typo">
              <div className="w-full">
                <h3 className="mb-8  xl:mb-16 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  Typeface & Colors
                </h3>
              </div>
              <div className="w-full lg:w-1/2">
                <h3 className="mb-2 lg:mb-4 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Sofia Pro
                </h3>
                <h5 className="mb-1 text-term-primary opacity-70">
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ
                </h5>
                <h5 className="mb-1 text-term-primary opacity-70">
                  abcdefghijklmnopqrstuvwxyz
                </h5>
                <h5 className="mb-1 text-term-primary opacity-70">
                  1234567890
                </h5>
              </div>
            </div>

            <div className="w-full">
              <div className="flex flex-wrap sm:flex-nowrap gap-2">
                <div className="soule-color-plate">
                  <h5 className="text-white font-poppings-bold text-center">
                    #06080D
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Result section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40 ">
          <div className="container mx-auto">
            <div className="w-full sm:w-2/3 lg:max-w-3xl">
              <h2 className="mb-4 lg:mb-8 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                The Result
              </h2>
              <p className="mb-2 lg:mb-3 xl:mb-6 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                Let's recap! We transformed the Souleway site into a
                contemporary design that retains visitors' attention and looks
                beautiful. We implemented visuals for an engaging experience and
                replaced heavy text block. The result is a credible appearance
                that has the intimacy of a mom-and-pop shop. With this engaging
                design and upgrades to the UI/UX our team created an online
                presence that highlighted key services, functioned effortlessly,
                reflected core values and allowed room for growth. Did we
                mention the client was a small business with a small budget? By
                working together, we achieved every goal on their wishlist at an
                affordable price. Who said you can't have your cake and eat it
                too.
              </p>

              <div className="w-full">
                <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  We've done it before and we can do it again – for YOU
                </h3>
              </div>
            </div>
          </div>
        </section>

        {/* slider section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="case-study-slider-bg">
            <div className="container container-rea mx-auto">
              <div
                id="myid"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Flickity
                  className={"case-studies-carousel cstm-du-casestudy-slider"}
                  elementType={"div"}
                  options={options}
                  ref={flRef}
                >
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Soule1}
                    alt="Souleway Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Soule2}
                    alt="Souleway Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Soule3}
                    alt="Souleway Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Soule4}
                    alt="Souleway Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Soule5}
                    alt="Souleway Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Soule6}
                    alt="Souleway Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Soule7}
                    alt="Souleway Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Soule8}
                    alt="Souleway Decodeup Technologies"
                  />
                </Flickity>
              </div>
            </div>
          </div>
        </section>

        {/* client testimonials carousel section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-fra mx-auto">
            <div className="pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>

        {/* additional case studies section */}
        <section className="pb-50 md:pt-50 lg:pt-62 xl:pt-0 lg:pb-1-20">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-36 text-center">
              <h3 className="d-head-title text-rp1  lg:text-h3 font-poppings-bold text-term-primary">
                Additional Case Studies
              </h3>
            </div>
            <div className="h-full flex flex-wrap">
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-cyan">
                <Link
                  to="/case-study/dental-health-care-website"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Dental Health Care Website
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=" w-full h-full object-cover"
                      src={addcs1}
                      alt="Contemporary Dentistry Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
              <div className=" pt-36 w-full sm:w-1/2 text-center bg-background-case">
                <Link
                  // to={post.fields.slug}
                  to="/case-study/truck-drivers-security-app"
                  className="h-full flex items-center justify-between flex-col text-center"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Truck Driver's Security App
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=" w-full h-full object-cover"
                      src={addcs2}
                      alt="C-watch Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <LiveChat />
      </Layout>
    </>
  )
}

export default SouleWay

export const pageQuery = graphql`
query soulewaypagequery {
  pageData: allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "/case-studies/ecommerce-of-minimalist-products.md/" } }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }     
}

`;